<script>
import { required } from "@vuelidate/validators";
import useVuelidate from '@vuelidate/core'
import { Modal } from 'bootstrap'
import { popModal, pushModal } from "@/modals";
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable'

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    draggable
  },
  props: {
    fields :{
      type: Array,
      required: true
    },
    status :{
      type: Object,
      required: true
    }
  },
  data() {
    return {
      item: {
        fields: [],
        visibleFor: []
      }
    };
  },
  validations() {
    const rules = {
      item: {
        name: { required }
      }
    };
    return rules;
  },
  created(){
    this.populate(this.status);
    this.title = this.isNew ? this.$t('directory.editFormNewStatusTitle') : this.$t('directory.editFormEditStatusTitle');
  },
  mounted(){
    var modal = new Modal(this.$refs.modal, {keyboard : false, backdrop: 'static', focus: true});
    modal.show();
    pushModal({modalInstance: modal, forceClose: this.close });
  },
  computed :{
    isNew () {
      return this.status.id ? false : true;
    },
    ...mapGetters('userGroups', {
      everyone: 'everyone',
      userGroups: 'items'
    }),
    availableFields() {
      return this.fields.filter(x => x.type !== 'DirectoryRelatedField' && !this.item.fields.find(a => a.field === x.id));
    }
  },
  methods: {
    populate(source) {
      let o = {
        id: source.id,
        name: source.name,
        code: source.code || null,
        countLimit: source.countLimit || 0,
        type: source.type || 'Active',
        color: source.color || '#383c40',
        fgColor: source.fgColor || '#141b2b',
        requiredCommentToEnter: source.requiredCommentToEnter,
        requiredCommentToLeave: source.requiredCommentToLeave,
        highlightEntireRecord: source.highlightEntireRecord || false,
        fields: (source.fields || []).filter(x =>  this.fields.find(a => a.id === x.field))
      };
      
      o.visibility = source.visibility ? JSON.parse(JSON.stringify(source.visibility || [])) : [];
      o.visibility = o.visibility.filter(a => this.userGroups.find(group => a.userGroup === group.id));
      o.visibility = o.visibility.map(a => this.userGroups.find(group => a.userGroup === group.id));

      this.item = o;
    },
    async close(res) {
      var modal = Modal.getInstance(this.$refs.modal);
      modal.hide();
      popModal();
      this.$emit("close", res);
    },
    async submit () {
      const isValid = await this.v$.$validate();
      if(isValid) {
        const dto = JSON.parse(JSON.stringify(this.item));
        this.$emit('save', dto);
        this.close();
      }
    },
    addField(item) {
      this.item.fields = [...this.item.fields, { field: item.id, hidden: false, readOnly: false, required: false }]
    },
    removeField(item) {
      this.item.fields = this.item.fields.filter(x => x != item);
    },
    getFieldName(id) {
      return (this.fields.find(x => x.id === id) || {}).name;
    }
  }
};
</script>

<template>
  <div
    class="modal"
    ref="modal"
  >
    <div class="modal-dialog  modal-dialog-scrollable modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title me-4">
            {{title}}
          </h5>
          <button
            type="button"
            class="btn-close"
            @click="close(null)"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row">
              <div class="col-lg-4">
                <div class="mb-3">
                  <label for="name-input" class="col-form-label">{{$t('directory.fields.statusName')}}</label>
                  <div>
                    <input 
                      type="text" 
                      class="form-control" 
                    
                      id="name-input" 
                      v-model="item.name"
                      :class="{ 'is-invalid': v$.item.name.$error }"
                    />
                    <div class="invalid-feedback">
                      {{ $t("common.pleaseEnter") }} {{ $t("directory.fields.statusName") }}
                    </div> 
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="mb-3">
                  <label for="name-input" class="col-form-label">Код</label>
                  <div>
                    <input 
                      type="text" 
                      class="form-control" 
                    
                      id="name-input" 
                      v-model="item.code"
                     
                    />
                    
                  </div>
                </div>
              </div>
               <div class="col-lg-4">
                <div class="mb-3">
                  <label for="name-input" class="col-form-label">{{$t('directory.fields.statusType')}}</label>
                  <div>
                    <select class="form-control" v-model="item.type">
                      <option value="Active">{{$t('directory.statusTypeActive')}}</option>
                      <option value="Success">{{$t('directory.statusTypeSuccess')}}</option>
                      <option value="Failure">{{$t('directory.statusTypeFailure')}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="form-check form-switch form-switch-md mb-3">
                <input class="form-check-input" type="checkbox" id="requiredCommentToEnter" v-model="item.requiredCommentToEnter"/>
                <label class="form-check-label">{{$t('directory.fields.requiredCommentToEnter')}}</label>
              </div>
              <div class="form-check form-switch form-switch-md  mb-3">
                <input class="form-check-input" type="checkbox" id="requiredCommentToLeave" v-model="item.requiredCommentToLeave"/>
                <label class="form-check-label">{{$t('directory.fields.requiredCommentToLeave')}}</label>
              </div>
              <div class="form-check form-switch form-switch-md  mb-3">
                <input class="form-check-input" type="checkbox" id="highlightEntireRecord" v-model="item.highlightEntireRecord"/>
                <label class="form-check-label">{{$t('directory.fields.highlightEntireRecord')}}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4">
                <div class="mb-3">
                  <label for="name-input" class="col-form-label">{{$t('directory.fields.countLimit')}}</label>
                  <div>
                    <input type="number" class="form-control" v-model="item.countLimit" :title="$t('directory.fields.countLimit')"/>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="mb-3">
                  <label for="name-input" class="col-form-label">{{$t('directory.fields.statusColor')}}</label>
                  <div class="input-group">
                    <input type="color" class="form-control" v-model="item.color" style="height:38px"/>
                    <input type="text" class="form-control" v-model="item.color"/>
                  </div>
                </div>
              </div>
              <div class="col-lg-4" v-if="item.highlightEntireRecord">
                <div class="mb-3">
                  <label for="name-input" class="col-form-label">{{$t('directory.fields.statusFgColor')}}</label>
                  <div class="input-group">
                    <input type="color" class="form-control" v-model="item.fgColor" style="height:38px"/>
                    <input type="text" class="form-control" v-model="item.fgColor"/>
                  </div>
                </div>
              </div>
            </div>
            <ul class="nav nav-tabs mt-4" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  data-bs-toggle="tab"
                  href="#statusfields"
                  role="tab"
                >
                  <span>{{$t('directory.fields.statusFieldsSettings')}}</span>
                </a>
              </li>
            </ul>
            <div class="tab-content py-3 text-muted">
              <div class="tab-pane active " id="statusfields" role="tabpanel">
                <div class="dropdown">
                  <a
                    class="btn btn-sm btn-light"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                  >
                    <font-awesome-icon icon="fa-solid fa-plus" />
                    <div class="text-sm-end d-none d-md-inline-block ms-2">
                      {{ $t("common.newRecord") }}
                    </div>
                    </a>
                  <div class="dropdown-menu">
                    <a
                      v-if="availableFields.length === 0" 
                      class="dropdown-item" href="javascript:void(0)" 
                    >
                      <span class="text-muted"> {{ $t("common.noRecordsFound") }}</span>
                    </a>
                    <a 
                      class="dropdown-item" href="javascript:void(0)" 
                      v-for="g in availableFields" 
                      :key="g"
                      @click="addField(g)"
                    >
                      {{g.name}}
                    </a>
                  </div>
                </div>
                <div class="table-responsive mt-2 bg-light p-2 mt-2">
                  <table class="table project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col" style="width: 60px">
                          <i class="mdi mdi-drag-variant"></i>
                        </th>
                        <th style="min-width:40%; width:40%">{{$t('directory.fields.fieldName')}}</th>
                        <th class="text-center">{{$t('directory.fields.fieldHidden')}}</th>
                        <th class="text-center" >{{$t('directory.fields.fieldReadonly')}}</th>
                        <th class="text-center" >{{$t('directory.fields.fieldRequired')}}</th>
                        <th scope="col" style="width: 80px"></th>
                      </tr>
                    </thead>
                    <tbody v-if="item.fields.length === 0">
                      <tr>
                        <td colspan="100"><span class="text-muted"> {{ $t("common.noRecordsFound") }}</span></td>
                      </tr>
                    </tbody>
                    <draggable
                      tag="tbody"
                      v-model="item.fields" 
                      group="statusfields" 
                      @start="drag=true" 
                      @end="drag=false" 
                      handle=".drag-area"
                      item-key="index"
                    >
                      <template #item="{element}">
                        <tr>
                          <td class="drag-area">
                            <a href="javascript:void(0)" >
                              <i class="mdi mdi-drag font-size-18"></i>
                            </a>
                          </td>
                          
                          <td style="min-width:40%; width:40%">{{getFieldName(element.field)}}</td>
                          <td class="text-center"><input class="form-check-input" type="checkbox" v-model="element.hidden"/></td>
                          <td class="text-center"><input class="form-check-input" type="checkbox" v-model="element.readOnly"/></td>
                          <td class="text-center"><input class="form-check-input" type="checkbox" v-model="element.required"/></td>
                          <td>
                            <ul class="list-inline mb-0">
                              
                              <li class="list-inline-item" >
                                <a
                                  href="javascript:void(0);"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Delete"
                                  @click="removeField(element)"
                                  class="btn btn-light btn-sm text-danger" 
                                
                                  >
                                  <font-awesome-icon icon="fa-solid fa-trash-can" />
                                  <div class="text-sm-end d-none d-md-inline-block ms-2">
                                    {{ $t("customFields.deleteItem") }}
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </template>
                    </draggable>
                  </table>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer row">
          <div class="col text-start">
            <button
              type="button"
              class="btn btn-light"
              @click="close(null)"
            >
              {{$t('common.closeButtonTitle')}}
            </button>
          </div>
           <div class="col text-end">
             <button type="button" class="btn btn-primary"
            
              @click="submit"
            >
              {{$t('common.saveButtonTitle')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
