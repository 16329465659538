<script>
import Layout from "../../layouts/main";
import { mapGetters, mapActions } from 'vuex';
import draggable from 'vuedraggable'
import { required } from "@vuelidate/validators";
import useVuelidate from '@vuelidate/core';
import { uuid } from 'vue-uuid';
import EditForm from '../custom-fields/edit-form'
import { customFieldTypeName } from '@/helpers'
import StatusForm from './status-form.vue'
import StatusColor from './status-color.vue'


import { Codemirror } from 'vue-codemirror'
import { javascript } from '@codemirror/lang-javascript'


export default {
  setup () {
    const extensions = [javascript()]
    return { v$: useVuelidate(), extensions }
  },
  components: {
    Layout,
    draggable,
    EditForm,
    StatusForm,
    StatusColor,
    Codemirror
  },
  props:{
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      errors: [],
      initializing: false,
      processing: false,
      loading: false,
      inited: false,
      statusFormVisible: false,
      status: null,

      editFieldItem: null,

      item: {
        fields: [],
        visibleFor: []
      }
    };
  },
  validations() {

    const rules = {
      item: {
        name: { required }
      }
    };

    return rules;
  },
  created() {
    document.title = this.pagetitle;
    this.init();
  },
  computed :{
    isNew() {
      return this.id.toLocaleLowerCase() === 'new' ? true: false;
    },
    pagetitle () {
      return this.isNew ? this.$t("directory.editFormNewItemTitle") : this.$t("directory.editFormEditItemTitle");
    },  
    ...mapGetters('boot', {
      appReady: 'ready'
    }),
    ...mapGetters('auth', {
      currentUser: 'currentUser'
    }),
    ...mapGetters('directories', {
      directories: 'items',
      deleting: 'deleting'
    }),
    ...mapGetters('userGroups', {
      everyone: 'everyone',
      userGroups: 'items'
    })
  },
  watch: {
    appReady (newValue, oldValue) {
      if(newValue !== oldValue) {
        this.init();
      }
    }
  },
  methods: {
    ...mapActions('directories', {
      create: 'create',
      update: 'update'
    }),
    getTypeName(type){
      return this.$t('customFieldTypes.' + customFieldTypeName[type]);
    },
    populate(source) {
      const o = {};
      o.name = source.name;
      o.onNewScript = source.onNewScript;
      o.onChangedScript = source.onChangedScript;
      o.inlineLabel = source.inlineLabel;
      o.name = source.name;
      o.code = source.code;
      o.type = source.type || 'Data';
      o.display = source.display || null;
      o.commentsEnabled = source.commentsEnabled ? true : false;
      o.filesEnabled = source.filesEnabled ? true : false;
      o.remindersEnabled = source.remindersEnabled ? true : false;
      o.changesTrackingEnabled = source.changesTrackingEnabled ? true : false;

      o.statusList = source.statusList ? JSON.parse(JSON.stringify(source.statusList || [])) : [];
      o.fields = source.fields ? JSON.parse(JSON.stringify(source.fields)) : [];
      o.fields = o.fields.filter(x => {
        if(x.type === 'Directory') {
          return this.directories.find(d => d.id === x.directoryId) ? true : false;
        }
        return true;
      });
      this.item = o;
    },
    createDto() {
      let dto = {
        type: this.item.type,
        onNewScript: this.item.onNewScript,
        onChangedScript: this.item.onChangedScript,
        inlineLabel: this.item.inlineLabel,
        name: this.item.name,
        code: this.item.code,
        display: this.item.display,
        commentsEnabled: this.item.commentsEnabled,
        filesEnabled: this.item.filesEnabled,
        changesTrackingEnabled: this.item.changesTrackingEnabled,
        remindersEnabled: this.item.remindersEnabled,
        fields: this.item.fields,
        statusList: this.item.statusList || []
      };
      return dto;
    },
    async init() {
      if(!this.appReady) return;

      if(this.initializing || this.inited) {
        return;
      }
    
      this.initializing = true;
      if(this.isNew) {
        this.populate({});
      } else {
        this.populate(this.directories.find(x => x.id === this.id));
      }
      this.inited = true;
      this.initializing = false;
    },
    async submit () {
      let isValid = await this.v$.$validate();
      this.errors = [];
    
      if(this.item.type === 'Process') {
        if(this.item.statusList.length === 0) {
          this.errors = [this.$t('directory.processMustHaveStatusList')];
          isValid = false;
        } else if(this.item.statusList.filter(x => x.type === 'Success' || x.type === 'Failure').length === 0) {
          this.errors = [this.$t('directory.processMustHaveSuccessorFailureStatus')];
           isValid = false;
        } else if(this.item.statusList.filter(x => x.type === 'Active').length === 0) {
          this.errors = [this.$t('directory.processMustHaveActiveStatus')];
           isValid = false;
        }
      }

      if(isValid) {
        
        const dto = this.createDto();
        if(dto.statusList) {
          dto.statusList.forEach(status => {
            status.fields = status.fields.filter(x => x.field);
          });
        }
        
        let req = this.isNew ? this.create({ dto }) : this.update({ id: this.id, dto: dto});
        req.then(() => {
          this.$toast.open({message: this.$t('common.savedText')});
          this.$router.push({name: 'directories'});
        })
        .catch(error => {
          this.errors = error;
        });
      }
    },
    removeField(field) {
      this.item.fields = this.item.fields.filter(x => x != field);
      if(this.item.display === field.id) {
        this.item.display = null;
      }
    },
    onFieldEditClose(field){
     
      if(field) {
     
        if(!field.id) {
          field.id = uuid.v1();
          
        }
        const id = (this.editFieldItem.definition || {}).id;
        if(id) {
          let o = [...this.item.fields];
          const index = o.findIndex(x => x.id === id);
          if(index >= 0){
            o[index] = field;
          } else {
            o = [...o, field];
          }
          this.item.fields = o;
        } else {
          this.item.fields = [...this.item.fields, field];
        }
        if(!this.item.display && field.type === 'String') {
          this.item.display = field.id;
        }
      }
      this.editFieldItem = null;
    },
    getUserGroupName(id) {
      const g = this.userGroups.find(x => x.id === id);
      if(!g) return '???';
      return g.everyone ? this.$t('userGroups.everyOneGroupName') : g.name;
    },
    addVisibilityItem(item) {
      this.item.visibleFor = [...this.item.visibleFor, item.id]
    },
    removeVisibilityItem(item) {
      this.item.visibleFor = this.item.visibleFor.filter(x => x != item);
    },
    addPermissionItem(item) {
      this.item.permissions = [...this.item.permissions, {userGroup: item.id, create: true, update: true, delete: false }]
    },
    removePermissionItem(item) {
      this.item.permissions = this.item.permissions.filter(x => x != item);
    },
    saveStatus(status) {
      if(status) {
     
        if(!status.id) {
          status.id = uuid.v1();
        }
        const id = this.status.id;
        if(id) {
          let o = [...this.item.statusList];
          const index = o.findIndex(x => x.id === id);
          if(index >= 0){
            o[index] = status;
          } else {
            o = [...o, status];
          }
          this.item.statusList = o;
        } else {
          this.item.statusList = [...this.item.statusList, status];
        }
      }
    },
    removeStatus(status) {
      this.item.statusList = this.item.statusList.filter(x => x !== status);
    },
    showStatusForm(status) {
      this.status = status || {};
      this.statusFormVisible = true;
    },
    closeStatusForm() {
      this.status = null;
      this.statusFormVisible = false;
    },
    getStatusRowStyle(status) {
      if(status.highlightEntireRecord) {
        return { 'background-color': status.color, 'color': status.fgColor || 'inherit' };
      } else {
        return {  };
      }
      
    }
  }
};
</script>

<template>
  <Layout :pagetitle="pagetitle + ( item.name ? (' [' + item.name + ']') : '')" :is-busy="initializing || loading || processing">
    <div class="small text-muted" v-if="!isNew">
      ID: {{id}}
    </div>
    <edit-form v-if="editFieldItem" @close="onFieldEditClose" :field="editFieldItem" :directory-type="item.type" :fields="item.fields">

    </edit-form>

    <status-form 
      v-if="statusFormVisible"
      :fields="item.fields" 
      :status="status"  
      @close="closeStatusForm"
      @save="saveStatus"
    >
    </status-form>

    <div v-if="inited" class="card mb-4">
      <div class="card-body">
       

        <div class="alert mt-4 mb-4 alert-dismissible alert-danger" v-if="errors.length > 0">
          <button type="button" aria-label="Close" class="btn-close" @click="errors=[]"></button>
          <ul class="list-unstyled">
            <li v-for="e in errors" :key="e">{{e}}</li>
          </ul>
        </div>
        <div>
          <div class="row gy-2 gx-3 align-items-center mb-3">
            <div class="col-md-3">
              <label  for="name">{{$t('directory.fields.name')}}</label>
              <input type="text" class="form-control" id="name" :placeholder="$t('directory.fields.name')" :class="{ 'is-invalid': v$.item.name.$error }" v-model="item.name"/>
              <div class="invalid-feedback">
                {{ $t("common.pleaseEnter") }} {{$t('directory.fields.name')}}
              </div> 
            </div>
            <div class="col-md-3">
              <label  for="name">{{$t('directory.fields.code')}}</label>
              <input type="text" class="form-control" id="code" :placeholder="$t('directory.fields.code')" v-model="item.code"/>
            </div>
            <div class="col-md-3">
              <label  for="name">{{$t('directory.fields.display')}}</label>
              <select class="form-control" v-model="item.display">
                <option :value="null">{{$t('directory.fields.number')}}</option>
                <option v-for="f in item.fields.filter(x => x.type === 'String' || x.type === 'Text')" :key="f.id" :value="f.id">{{f.name}}</option>
              </select>
            </div>
            <div class="col-md-3">
              <label  for="name">{{$t('directory.dirType')}}</label>
              <select class="form-control" v-model="item.type" :disabled="!isNew">
                <option value="Data">{{$t('directory.directoryTypeData')}}</option>
                <option value="Process">{{$t('directory.directoryTypeProcess')}}</option>
              </select>
            </div>
            
            
            <!-- <div class="col-md-auto">
              <div class="form-check form-switch form-switch-md mb-3">
                <input class="form-check-input" type="checkbox" id="remindersEnabled" v-model="item.remindersEnabled"/>
                <label class="form-check-label" for="remindersEnabled">{{$t('directory.fields.remindersEnabled')}}</label>
              </div>
            </div> -->
          </div>
          <div class="row gy-2 gx-3 mt-3 align-items-center  mb-3">
            <div class="col-md-auto">
              <div class="form-check form-switch form-switch-md mb-3">
                <input class="form-check-input" type="checkbox" id="commentsEnabled" v-model="item.commentsEnabled"/>
                <label class="form-check-label" for="commentsEnabledt">{{$t('directory.fields.commentsEnabled')}}</label>
              </div>
            </div>
            <div class="col-md-auto">
              <div class="form-check form-switch form-switch-md mb-3">
                <input class="form-check-input" type="checkbox" id="filesEnabled" v-model="item.filesEnabled"/>
                <label class="form-check-label" for="filesEnabled">{{$t('directory.fields.filesEnabled')}}</label>
              </div>
            </div>
            <div class="col-md-auto">
              <div class="form-check form-switch form-switch-md mb-3">
                <input class="form-check-input" type="checkbox" id="changesTrackingEnabled" v-model="item.changesTrackingEnabled"/>
                <label class="form-check-label" for="changesTrackingEnabled">{{$t('directory.fields.changesTrackingEnabled')}}</label>
              </div>
            </div>
            <div class="col-md-auto">
              <div class="form-check form-switch form-switch-md mb-3">
                <input class="form-check-input" type="checkbox" id="inlineLabel" v-model="item.inlineLabel"/>
                <label class="form-check-label" for="inlineLabel">{{$t('directory.fields.inlineLabel')}}</label>
              </div>
            </div>
          </div>
        </div>
        <ul class="nav nav-tabs mt-4" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              data-bs-toggle="tab"
              href="#fields"
              role="tab"
            >
              <span>{{$t('directory.fields.sectionTitle')}}</span>
            </a>
          </li>
          <li class="nav-item" v-if="item.type === 'Process'">
            <a
              class="nav-link"
              data-bs-toggle="tab"
              href="#statusList"
              role="tab"
            >
              <span>{{$t('common.statusList')}}</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              data-bs-toggle="tab"
              href="#scripts"
              role="tab"
            >
              <span>{{$t('directory.fields.scripts')}}</span>
            </a>
          </li>
        </ul>
        <div class="tab-content py-3 text-muted">
          <div class="tab-pane active " id="fields" role="tabpanel">
            <div class="d-flex flex-wrap">
            
              <div class="">
                <a href="javascript: void(0);" class="btn btn-sm btn-light" @click="editFieldItem = { definition: {}}">
                  <font-awesome-icon icon="fa-solid fa-plus" />
                  <div class="text-sm-end d-none d-md-inline-block ms-2">
                     {{ $t("customFields.newItem") }}
                  </div>
                </a>
              </div>
            </div>
            <div class="table-responsive bg-light p-2 mt-2">
              <table
                class="table project-list-table table-nowrap align-middle table-borderless"
                style=""
              >
                <thead>
                  <tr>
                    <th scope="col" style="width: 60px">
                      <i class="mdi mdi-drag-variant"></i>
                    </th>
                    <th scope="col" style="width: 40px"></th>
                    <th scope="col">{{ $t("customFields.fields.name") }}</th>
                    <th scope="col">{{ $t("customFields.fields.type") }}</th>
                  
                    <th scope="col">{{ $t("customFields.fields.code") }}</th>
                  
                  
                    <th scope="col">{{ $t("customFields.fields.required") }}</th>
                    <th scope="col">{{ $t("customFields.fields.unique") }}</th>
                    <th scope="col">{{ $t("customFields.fields.hidden") }}</th>
                    <th scope="col">{{ $t("customFields.fields.visibleOnGrid") }}</th>
                    <th scope="col" v-if="item.type === 'Process'">{{ $t("customFields.fields.visibleOnBoard") }}</th>
                    <th scope="col">{{ $t("customFields.fields.description") }}</th>
                    
                    <th scope="col" style="width: 80px"></th>
                  </tr>
                </thead>
                <tbody  v-if="item.fields.length === 0">
                  <tr>
                    <td colspan="100">
                      <em class="text-muted">
                        {{ $t("customFields.noRecordsFound") }}
                      </em>
                    </td>
                  </tr>
                </tbody>
                <draggable
                  tag="tbody"
                  v-model="item.fields" 
                  group="fields" 
                  @start="drag=true" 
                  @end="drag=false" 
                  handle=".drag-area"
                  item-key="index"
                >
                  <template #item="{element}">
                    <tr>
                      
                      <td class="drag-area">
                        <a href="javascript:void(0)" >
                          <i class="mdi mdi-drag font-size-18"></i>
                        </a>
                      </td>
                      
                      <td>
                        <ul class="list-inline mb-0">
                          <li class="list-inline-item" >
                            <a href="javascript:void(0)" 
                              class="btn btn-light btn-sm"  
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              @click="editFieldItem = { definition: JSON.parse(JSON.stringify(element))}"
                            >
                              <font-awesome-icon icon="fa-solid fa-pen" />
                              <div class="text-sm-end d-none d-md-inline-block ms-2">
                                {{ $t("customFields.editItem") }}
                              </div>
                              
                            </a>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <b>{{element.name}}</b>
                      </td>
                      <td>
                        {{getTypeName(element.type)}}
                        <b v-if="element.type === 'Directory'">[{{(directories.find(x => x.id === element.directoryId) || {}).name}}]</b>
                      </td>
                      
                      <td>
                        {{element.code}}
                      </td>
                      
                      <td>
                        <span v-if="element.required">
                          <i class="fas fa-check"/>
                        </span>
                      </td>
                      <td>
                        <span v-if="element.isUnique">
                          <i class="fas fa-check"/>
                        </span>
                      </td>
                       <td>
                        <span v-if="element.hidden">
                          <i class="fas fa-check"/>
                        </span>
                      </td>
                      <td>
                        <span v-if="element.visibleOnGrid">
                          <i class="fas fa-check"/>
                        </span>
                      </td>
                      <td  v-if="item.type === 'Process'">
                        <span v-if="element.visibleOnBoard">
                          <i class="fas fa-check"/>
                        </span>
                      </td>
                      <td>
                          {{element.description}}
                      </td>
                      <td>
                        <ul class="list-inline mb-0">
                          
                          <li class="list-inline-item" >
                            <a
                              href="javascript:void(0);"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Delete"
                              @click="removeField(element)"
                              class="btn btn-light btn-sm text-danger" 
                            
                              >
                              <font-awesome-icon icon="fa-solid fa-trash-can" />
                              <div class="text-sm-end d-none d-md-inline-block ms-2">
                                {{ $t("customFields.deleteItem") }}
                              </div>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </template>
                </draggable>
              </table>
            </div>
          </div>
          <div class="tab-pane" id="statusList" role="tabpanel" v-if="item.type === 'Process'">
            <div class="d-flex flex-wrap">
              
              <div class="">
                <a
                    class="btn btn-sm btn-light"
                    role="button"
                  
                  
                    @click="showStatusForm(null)"
                  >
                    <font-awesome-icon icon="fa-solid fa-plus" />
                    <div class="text-sm-end d-none d-md-inline-block ms-2">
                      {{ $t("common.newRecord") }}
                    </div>
                  </a>
              </div>
            </div>
            <div class="table-responsive bg-light p-2 mt-2">
              <table
                class="table project-list-table table-nowrap align-middle table-borderless"
                style=""
              >
                <thead>
                  <tr>
                    <th scope="col" style="width: 60px">
                      <i class="mdi mdi-drag-variant"></i>
                    </th>
                     <th scope="col" style="width: 40px"></th>
                    <th scope="col">{{ $t("directory.fields.statusName") }}</th>
                     <th scope="col">Код</th>
                    <th scope="col">{{ $t("directory.fields.statusType") }}</th>
                    
                    <th scope="col" style="width: 80px"></th>
                  </tr>
                </thead>
                <tbody  v-if="item.statusList.length === 0">
                  <tr>
                    <td colspan="100">
                      <em class="text-muted">
                        {{ $t("common.noRecordsFound") }}
                      </em>
                    </td>
                  </tr>
                </tbody>
                <draggable
                  tag="tbody"
                  v-model="item.statusList" 
                  group="statusList" 
                  @start="drag=true" 
                  @end="drag=false" 
                  handle=".drag-area"
                  item-key="index"
                >
                  <template #item="{element}">
                    <tr :style="getStatusRowStyle(element)">
                      
                      <td class="drag-area">
                        <a href="javascript:void(0)" >
                          <i class="mdi mdi-drag font-size-18"></i>
                        </a>
                      </td>
                      <td>
                        <ul class="list-inline mb-0">
                          <li class="list-inline-item" >
                            <a href="javascript:void(0)" 
                              class="btn btn-light btn-sm"  
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              @click="showStatusForm(element)"
                            >
                              <font-awesome-icon icon="fa-solid fa-pen" />
                              <div class="text-sm-end d-none d-md-inline-block ms-2">
                                {{ $t("customFields.editItem") }}
                              </div>
                              
                            </a>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <status-color :color="element.color"></status-color> {{element.name}}
                      </td>
                       <td>
                        {{element.code}}
                      </td>
                      <td>
                        <span v-if="element.type === 'Active'"> {{ $t("directory.statusTypeActive") }}</span>
                        <span v-if="element.type === 'Success'"> {{ $t("directory.statusTypeSuccess") }}</span>
                        <span v-if="element.type === 'Failure'"> {{ $t("directory.statusTypeFailure") }}</span>
                      </td>
                      
                      <td>
                        <ul class="list-inline mb-0">
                          
                          <li class="list-inline-item" >
                            <a
                              href="javascript:void(0);"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              @click="removeStatus(element)"
                              class="btn btn-light btn-sm text-danger" 
                            >
                              <font-awesome-icon icon="fa-solid fa-trash-can" />
                              <div class="text-sm-end d-none d-md-inline-block ms-2">
                                {{ $t("customFields.deleteItem") }}
                              </div>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </template>
                </draggable>
              </table>
            </div>
          </div>
          <div class="tab-pane" id="scripts"  role="tabpanel">
            <div class="alert alert-secondary mb-2">
              <p>
                {{$t('directory.allFieldsAvaialableWith')}}: <code>this.fields.createdAt</code> where <code>createdAt</code> is field's code.
              </p>
              <p>
                {{$t('directory.statusAvaialableWith')}}: <code>this.status</code>.
              </p>
            </div>
            <div class="alert alert-secondary">
              <h6>{{$t('directory.supportedNextJsLibraries')}}</h6>
              <ul>
                <li>
                  <a href="https://momentjs.com">momentjs</a>.
                  <small>A JavaScript date library for parsing, validating, manipulating, and formatting dates. </small><br/>
                  <p>
                    <em>Example of usage:</em><br/>
                    <code style="display: block;">
                      this.fields.createdAt = moment().format('YYYY-MM-DD');
                    
                    </code>
                    <code style="display: block;">
                      this.fields.dueDate = moment(this.fields.createdAt, "YYYY-MM-DD").add(45, 'days').format('YYYY-MM-DD');
                    </code>
                  </p>
                </li>
                <li>
                  <a href="https://lodash.com">lodash</a>. <small> A modern JavaScript utility library delivering modularity, performance & extras. </small><br/>
                  <p>
                    <em>Example of usage:</em><br/>
                    <code style="display: block;">
                      lodash.defaults({ 'a': 1 }, { 'a': 3, 'b': 2 });
                    </code>
                    <code style="display: block;">
                      lodash.partition([1, 2, 3, 4], n => n % 2);
                    </code>
                    <small class="text-primary mt-2">{{$t('directory.lodashAttention')}}</small>
                  </p>
                </li>
              </ul>
            </div>
            <div class="">
              <b>{{$t('directory.fields.onNewScript')}}</b>
              <codemirror
                v-model="item.onNewScript"
                placeholder=""
                :style="{ height: '200px' }"
                :autofocus="true"
                :indent-with-tab="true"
                :extensions="extensions"
                :tab-size="2"
              
              />
            </div>
            <div class=" mt-4">
              <b class="">{{$t('directory.fields.onChangedScript')}}</b>
              <codemirror
                v-model="item.onChangedScript"
                placeholder=""
                :style="{ height: '200px' }"
                :autofocus="true"
                :indent-with-tab="true"
                :extensions="extensions"
                :tab-size="2"
              
              />
            </div>
           
          </div>
        </div>
       </div>
      <div class="card-body">
        <div class="row gy-2 gx-3 align-items-center  ">
          <div class="col">

          </div>
          <div class="col text-end">
            <button type="button" 
              class="btn btn-primary"
              :disabled="processing || loading" 
              @click="submit"
            >
              <i class="fa fa-spin fa-spinner me-2" v-if="processing"/>
              <i class="fa fa-save me-2" v-if="!processing"/>
              {{$t('common.saveButtonTitle')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
